<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom" style="background-color: #003b7d;border-radius:10px 0 10px 0">
      <nav class="navbar navbar-expand-lg navbar-light p-0 flex align-items-center justify-content-between">
        <div class="bg-white p-2 rounded mx-4 align-self-center position-static" v-if="$route.meta.layout !== 'two-sidebar'">
          <div class="wrapper-menu" @click="sidebarMini">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
          </div>
        </div>
<!--        <b-collapse id="nav-collapse" is-nav>-->
<!--          <ul class="navbar-nav ml-auto navbar-list">-->
<!--            <li class="nav-item" v-nav-toggle>-->
<!--              <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="selectedLang.image"-->
<!--                                                                                    alt="img-flaf"-->
<!--                                                                                    class="img-fluid mr-1"-->
<!--                                                                                    style="height: 16px; width: 16px;"/><i-->
<!--                class="ri-arrow-down-s-line"></i></a>-->
<!--              <div class="iq-sub-dropdown">-->
<!--                <a class="iq-sub-card" v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang)">-->
<!--                  <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2"/>{{ lang.title }}-->
<!--                </a>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </b-collapse>-->
        <div class="gap-3 d-flex align-items-center" style="gap: 15px">
          <select class="py-1 px-1 rounded-sm bg-white" style="appearance: none; outline: none" v-model="langSelected" @change="langChange()" name="" id="">
            <option :value="lang.value"  v-for="(lang, i) in langsOptions" :key="i">
              {{ lang.title }}
            </option>
          </select>
        <ul class="navbar-list">
          <li class="rounded" v-nav-toggle>
            <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
              <div v-if="userInfo"
                   class="img-avatar p-3 bg-white rounded d-flex align-items-center justify-content-center"
                   style="width: 50px;height: 50px; margin-right: 5px;">
                {{ userInfo.name[0] }}
              </div>
              <div class="caption">
                <h6 class="mb-0 line-height text-white" v-if="userInfo">{{ userInfo.name }}</h6>
                <span class="font-size-12 text-white">{{ $t('navbar.active-user') }}</span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3">
                    <h5 class="mb-0 text-white line-height" v-if="userInfo">{{ userInfo.name }}</h5>
                    <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span>
                  </div>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="btn btn-primary dark-btn-primary text-white" @click="logout" role="button">{{
                        $t('nav.user.signout')
                      }}<i class="ri-login-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { core, APPNAME } from '../../../../config/pluginInit'
// import langChange from '../../../../Mixins/langchange'
export default {
  name: 'HeaderStyle',
  // mixins: [langChange],
  data () {
    return {
      appName: APPNAME,
      langSelected: 'en'
    }
  },
  mounted () {
    this.langSelected = this.$i18n.locale
    if (this.$i18n.locale === 'en') {
      this.modeChange({
        rtl: false,
        dark: false
      })
    } else {
      this.modeChange({
        rtl: true,
        dark: false
      })
    }
  },
  methods: {
    ...mapActions({
      modeChange: 'Setting/layoutModeAction'
    }),
    langChange () {
      this.$i18n.locale = this.selectedLang
      localStorage.setItem('booking-lang', this.langSelected)
      window.location.reload()
    },
    sidebarMini () {
      core.triggerSet()
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('permissions')
      localStorage.removeItem('access_token')
      localStorage.removeItem('booking-admin-info')
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    ...mapActions({
      removeToCart: 'Ecommerce/removeToCartAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    })

  },

  computed: {
    ...mapGetters({
      cartCount: 'Ecommerce/cartCountState',
      cartItems: 'Ecommerce/cartState',
      bookmark: 'Setting/bookmarkState',
      langsOptions: 'Setting/langOptionState',
      selectedLang: 'Setting/langState'
    })
  }
}
</script>
